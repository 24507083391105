/*
########   ##     ##  ##    ##    ######    ########   ####   #######   ##    ##    ######    
##         ##     ##  ###   ##   ##    ##      ##       ##   ##     ##  ###   ##   ##    ##   
##         ##     ##  ####  ##   ##            ##       ##   ##     ##  ####  ##   ##         
######     ##     ##  ## ## ##   ##            ##       ##   ##     ##  ## ## ##    ######    
##         ##     ##  ##  ####   ##            ##       ##   ##     ##  ##  ####         ##   
##         ##     ##  ##   ###   ##    ##      ##       ##   ##     ##  ##   ###   ##    ##   
##          #######   ##    ##    ######       ##      ####   #######   ##    ##    ######    
*/

var allBreakpoints = Unison.fetch.all();

var navigation = responsiveNav(".nav-main",{
	label: "<span class='icon-menu'></span> Menu"
});

var subNavigation = responsiveNav(".nav-scroll",{
	label: "<span class='icon-menu'></span> Menu"
});


/*
########    #######   ##     ##       ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###       ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####       ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##       ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##       ##     ##  ########   ##     ##  ########      ##      
*/


/**
 * jQuery stuff
 */
$(document).ready(function() {
	// Diminution du temps de réaction pour Touch Screen
	FastClick.attach(document.body);

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	// match height
	$(".harmonize").matchHeight();

	// toggle member header
	$(".js-connection").click(function(e){
		e.preventDefault();
		$(".member-form").slideToggle();
	});

	var waypoints = $('.container').waypoint({
		handler: function(direction) {
			if( direction == "down" ){
				$(".scroll-menu").addClass('is-active');
			}else{
				$(".scroll-menu").removeClass('is-active');
				subNavigation.close();
			}
		}
	});

	// LightGallery

	$('a.zoom').each(function(){
		$(this).attr('data-src', $(this).attr('href'));
		if( $(this).find('img.aligndroite').length > 0 ){
		    $(this).addClass('aligndroite');
        }
        if( $(this).find('img.aligngauche').length > 0 ){
            $(this).addClass('aligngauche');
        }
	});

	$("p:has(a.zoom)").lightGallery(); 

	// sub nav
	if( $(".sub-nav").length > 0 ){
		var navigation = responsiveNav(".sub-nav",{
			label: "<span class='icon-menu'></span> "+$(".sub-nav li:eq(0) a").html()
		});
	}
	$(".sub-nav a.active").each(function(){
		$(this).parents('li').addClass('active');
	});

	// Unison.on('tablet', function() {
		$(".sub-nav ul > li > a.redirect").click(function(e){
			e.preventDefault();
			$(this).next('ul').slideToggle(function(){
				navigation.resize();
			});
		});
	// });

	// owl-carousel
	$('.reference-slick').slick({
		dots: false,
		infinite: false,
		speed: 300,
		// variableWidth: true,
		prevArrow: "<span class='icon-keyboard-arrow-left slick-nav'></span>",
		nextArrow: "<span class='icon-keyboard-arrow-right slick-nav'></span>",
		slidesToShow: 4,
  		slidesToScroll: 4,
  		adaptiveHeight: true
	});

	function SVG(tag) {
	    return document.createElementNS('http://www.w3.org/2000/svg', tag);
	}


    var cX = $('circle').attr('cx');
    var cY = $('circle').attr('cy');
    var r = $('circle').attr('r');
    var r2 = parseFloat(r * 2);

    var stroke = [];

    $(".animation-anchors a").each(function(){
    	stroke.push( $(this).attr('data-dash') );
    });

	function replaceCirclesWithPaths(parentElement) {

	    var circles = $(parentElement).find('circle');

	    $.each(circles, function() {

	    	// var convertedPath = 'M '+ (parseFloat(cY)+parseFloat(r)) +', '+ (parseFloat(cY)-parseFloat(r)) +' m' + (-r) + ', 0 ' + 'a '+r+', '+r+' 0 0,1 0, '+r2+' a ' +r+ ', ' +r+ ' 0 0,1 0,-'+r2 ;
	        var convertedPath = 'M 250, 79 m'+ (-r) + ',0 ' + 'a '+r+', '+r+' 0 0,1 312, 251 a ' +r+ ', ' +r+ ' 0 0,1 -312,-251'  ;
	      
	        
	        $(SVG('path'))
	        .attr('d', convertedPath)
	        .attr('fill', 'none')
	        .attr('stroke', '#111a32')
	        .attr('stroke-width', 2)
	        .insertAfter(this);

	    });

	    var paths = $(parentElement).find('path');

	    //for each PATH..
	    $.each( paths, function(i) {

	        //get the total length
	        var totalLength = this.getTotalLength();

	        //set PATHs to invisible
	        $(this).css({
	            'stroke-dashoffset': totalLength,
	            'stroke-dasharray': totalLength + ' ' + totalLength
	        });
	    });

	}

	function goSVGAnimation( _dash, _index ){

        //animate
        $('path').animate({
            'stroke-dashoffset': _dash
        }, {
            duration: 600,

	        progress: function(){
	        	checkSVGProgress( parseFloat( $(this).css('stroke-dashoffset') ) );
	        },
	    	done: function(){
	    		$(".absolute-child:eq("+ _index +")").addClass('active');   		
	    	},
	    	start: function(){
	    		$(".absolute-child").removeClass('active');   		
	    	}
        });

	}

	function checkSVGProgress( _stroke ){

		$.each(stroke, function(i){
	    	if( _stroke <= this ) $(".animation-anchors a:eq("+i+")").addClass('passed');
	    	else $(".animation-anchors a:eq("+i+")").removeClass('passed');	
	    	// $(".absolute-child").removeClass('active');   		
		});

	}

	replaceCirclesWithPaths($('svg.animation-svg'));

	$(".animation-anchors a").click(function(e){
		e.preventDefault();
		goSVGAnimation($(this).attr('data-dash'), $(this).parent().index());
	    $(".animation-anchors a").removeClass('active');	 
	    $(this).addClass('active');
	    $(".process-arrow").hide();
	});

	$("#map").gmapsPlugin({
		lat:          44.837177,
		lng:          -0.5804905999999619,
		zoom:         16,
		markerImage:  "/img/marker.png",
		markerWidth:120,
		markerHeight:120,
		styles : [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"administrative.country","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"administrative.country","elementType":"geometry.stroke","stylers":[{"visibility":"on"}]},{"featureType":"administrative.country","elementType":"labels.text","stylers":[{"hue":"#fff600"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#cad3d5"},{"visibility":"on"}]}]
	});

	$("#mapOEC").gmapsPlugin({
		lat:          45.76324325061908,
		lng:          4.834248782373196,
		zoom:         16,
		markerImage:  "/img/marker.png",
		markerWidth:120,
		markerHeight:120,
		styles : [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"administrative.country","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"administrative.country","elementType":"geometry.stroke","stylers":[{"visibility":"on"}]},{"featureType":"administrative.country","elementType":"labels.text","stylers":[{"hue":"#fff600"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#cad3d5"},{"visibility":"on"}]}]
	});

	if( $("#arrow").length > 0 )
		new Vivus('arrow', {type: 'delayed', start: 'autostart', duration: 50});

	/*
	########   ########    ######    ##         ########   ##     ##  ########   ##    ##   ########   
	##     ##  ##         ##    ##   ##         ##         ###   ###  ##         ###   ##      ##      
	##     ##  ##         ##         ##         ##         #### ####  ##         ####  ##      ##      
	########   ######     ##   ####  ##         ######     ## ### ##  ######     ## ## ##      ##      
	##   ##    ##         ##    ##   ##         ##         ##     ##  ##         ##  ####      ##      
	##    ##   ##         ##    ##   ##         ##         ##     ##  ##         ##   ###      ##      
	##     ##  ########    ######    ########   ########   ##     ##  ########   ##    ##      ##      
	*/

	$('.popup-reglement').magnificPopup({
		type:'inline'
	});

	$(".payplug-btn").click(function(e){
		e.preventDefault();
		var payplug_url = $(this).data("url");
		Payplug.showPayment(payplug_url);
	});


   
});
