var lang = $("html").attr("lang");

var changeLangue = function() {
	$.ajax({
		type: "POST",
		data: "source="+$("select[name='~source']").val(),
		url: "/"+lang+"/traduction/",
		success: function(retour) {
			$("select[name='~cible[]']").empty().append(retour);
			$("select[name='~cible[]']").multiselect('refresh');
		}
	});
};

var checkINPUT = function(field, rules, i, options){
	if (field.val() == field.attr('default')) {
		return options.allrules.required.alertText;
	}
};

var iniFiles = function() {
	$("#FormDevis div.files a").unbind().click(function(e) {
		e.preventDefault();
		$span = $(this).parent();
		f = $(this).next().val();
		$.ajax({
			type: "POST",
			data : {f: f},
			url: "/"+lang+"/traduction/",
			success: function(retour) {
				$span.fadeOut(400, function() {
					$span.remove();
				});
			}
		});
	});
};

/*
########    #######   ##     ##       ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###       ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####       ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##       ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##       ##     ##  ########   ##     ##  ########      ##      
*/


var sweetAlert = function(txt, type, timer){
	timer = typeof timer !== 'undefined' ?  timer : null;
	swal({
		title:             txt,
		type:              type,
		timer:             timer,
		allowOutsideClick: false,
		allowEscapeKey: false
	});
};


/**
 * jQuery stuff
 */
$(document).ready(function() {


	$("form").validationEngine({
		scroll : false,
		promptPosition: 'centerRight',
		maxErrorsPerField:1
	});
	
	// JEU CONCOURS
	$("#FormConcours").submit(function(){
		if($("#FormConcours").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi&type=concours';
			sweetAlert("Envoi en cours", "warning");
			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/contact/",
				success: function(retour) {
					if(retour[0] > 0){ 
						sweetAlert(retour[1], "success", 2000);
					}else{
						sweetAlert(retour[1], "error", 2000);
					}
				}
			});
		}
		return false;
	});

	// SERVICE
	$("#fService select[name='service-type']").change(function(){
		var data = "service-type="+$(this).val();
		$.ajax({
			type: "POST",
			data : data,
			url: "/"+lang+"/service/",
			success: function(retour) {				
				$("#fService select[name='service-language']").empty().html(retour);
			}
		});
	});

	$("#fService").submit(function(){
		if($("#fService").validationEngine('validate')){
			window.location = $("#fService select[name='service-language'] option:checked").val();
		}
		return false;
	});

	$("#fServiceContact").submit(function(){
		if($("#fServiceContact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			sweetAlert( $(this).attr('data-send'), "warning");
			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/service/",
				success: function(retour) {
					if(retour[0] > 0){ 
						sweetAlert(retour[1], "success", 2000);
					}else{
						sweetAlert(retour[1], "error", 2000);
					}
				}
			});
		}
		return false;
	});


	// CONTACT
	$("#fContact").submit(function(){
		if($("#fContact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi&type=contact';
			// var div = $("#fContact").empty().html('<p>envoi en cours...</p>');
			sweetAlert( $(this).attr('data-send'), "warning");
			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/contact/",
				success: function(retour) {
					if(retour[0] > 0){ 
						sweetAlert(retour[1], "success", 2000);
					}else{
						sweetAlert(retour[1], "error", 2000);
					}
				}
			});
		}
		return false;
	});
	

	// IDENTIFICATION
	$(".fLogin").submit(function(){
		if($(".fLogin").validationEngine('validate')){

			var data = $(this).serialize() + '&act=login';			
			sweetAlert( $(this).attr('data-send') , "warning");

			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/login/",
				success: function(retour) {
					// div.html(retour[1]);
					if(retour[0] > 0){ 
						sweetAlert(retour[1], "success");
						if( $(".fLogin").find("input[name=url]").length > 0 ){
							setTimeout(function(){
								window.location = $(".fLogin").find("input[name=url]").val();
							}, 2000);
						}else{
							setTimeout(function(){
								location.reload();
							}, 2000);								
						}
					}else{
						sweetAlert(retour[1], "error", 2000);
					}
				}
			});
		}
		return false;
	});

	// INSCRIPTION
	$("#FormInscription").submit(function(){
		if($("#FormInscription").validationEngine('validate')){
			var data = $(this).serialize() + '&act=inscription';		
			sweetAlert( $(this).attr('data-send'), "warning");
			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/login/",
				success: function(retour) {
					type = ( retour[0] > 0 ) ? "success" : "error";
					sweetAlert(retour[1], type, 2000);

					if(retour[0] > 0){ 
						sweetAlert(retour[1], "success");
						if( $(".fInscription").find("input[name=url]").length > 0 ){
							setTimeout(function(){
								window.location = $(".fInscription").find("input[name=url]").val();
							}, 2000);
						}else{
							setTimeout(function(){
								location.reload();
							}, 2000);								
						}
					}else{
						sweetAlert(retour[1], "error", 2000);
					}
				}
			});
		}
		return false;
	});
	
	// MOT DE PASSE OUBLIE
	$("#FormOubli").submit(function(){
		if($("#FormOubli").validationEngine('validate')){
			var data = $(this).serialize() + '&act=oubli';		
			sweetAlert( $(this).attr('data-send') , "warning");
			$.ajax({
				type: "POST",
				data : data,
				dataType : 'json',
				url: "/"+lang+"/login/",
				success: function(retour) {
					type = ( retour[0] > 0 ) ? "success" : "error";
					sweetAlert(retour[1], type, 2000);
				}
			});
		}
		return false;
	});

	// DEVIS

	// Retour étape 1
	$("a.modifierDemande").click(function(e) {
		e.preventDefault();
		$.ajax({
			type: "POST",
			data : "etape=1",
			url: "traduction.html",
			success: function(retour) {
				window.location = "traduction.html";
			}
		});
	});

	// Retour étape 1
	$("a.modifierCoord").click(function(e) {
		e.preventDefault();
		$.ajax({
			type: "POST",
			data : "etape=2",
			url: "traduction.html",
			success: function(retour) {
				window.location = "traduction.html";
			}
		});
	});

	// Multiselect
	$("#FormDevis select[name='~source']").multiselect({
		classes: 'multiselect',
		multiple: false,
		header: false,
		noneSelectedText: $("#FormDevis select[name='~source']").attr('data-default'),
		selectedList: 1
	});
	$("#FormDevis select[name='~source']").change(changeLangue);
	
	$("#FormDevis select[name='~cible[]']").multiselect({
		classes: 'multiselect',
		header: false,
		noneSelectedText: $("#FormDevis select[name='~cible[]']").attr('data-default'),		
		selectedList: 99
	});

	// Form validation
	$("#FormDevis").submit(function() {
		var validate = true;
		var options = $(this).data('jqv');

		$("#FormDevis select").each(function() {
			id = $(this).prop("id");
			if ($(this).val() == null) {
				$(this).nextAll("button").prop("id","multi"+id);
				validate = false;
				$("#multi"+id).validationEngine('showPrompt', options.allrules.required.alertText, 'error', false, true);
			} else {
				$("#multi"+id).validationEngine('hide');
			}
		});

		if($("#_files span").length <= 0 && $("#_texte").val()=="") {

			validate = false;
			$("#_files").validationEngine('showPrompt', options.allrules.groupRequired.alertText, 'error');

		}else if($("#_files").attr('data-nb') > 0 && $("#_files").attr('data-nb') > $("#_files span:visible").length){

			validate = false;
			$("#_files").validationEngine('showPrompt', $(".min-files").html(), 'error');

		}else{

			$("#_files").validationEngine('hide');

		}

		if(validate && $("#FormDevis").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			// $(this).find("button[type=submit]").empty().addClass("loading").attr("disabled","disabled");
			sweetAlert($(this).data('send'), "warning");
			$.ajax({
				type: "POST",
				data : data,
				url: "/"+lang+"/traduction/",
				success: function(retour) {
					window.location = "/"+lang+"/traduction/";
					$(this).find("button[type=submit]").removeAttr("disabled");
				}
			});
		}
		return false;
	});



/*
##     ##  ########   ##          #######      ###     ########   
##     ##  ##     ##  ##         ##     ##    ## ##    ##     ##  
##     ##  ##     ##  ##         ##     ##   ##   ##   ##     ##  
##     ##  ########   ##         ##     ##  ##     ##  ##     ##  
##     ##  ##         ##         ##     ##  #########  ##     ##  
##     ##  ##         ##         ##     ##  ##     ##  ##     ##  
 #######   ##         ########    #######   ##     ##  ########   
*/
	// File upload
	/*
	$('#FormDevis #_fileupload').uploadify({
		'fileSizeLimit': '30MB',
		'height': 40,
		'width': 100,
		'multi': true,
		'fileTypeExts' : '*.doc; *.docx; *.txt',
		'swf': '/uploadify.swf',
		'uploader': '/uploadify.php?l='+lang,
		'buttonText': $('#FormDevis #_fileupload').attr('data-default'),
		'buttonClass': 'parcourir',
		'onUploadError' : function(file, errorCode, errorMsg, errorString) {
            alert('The file ' + file.name + ' could not be uploaded: ' + errorString);
        },
		'onUploadSuccess' : function(file, data, response) {
			$("div.files").append('<span>'+file.name+' <a href="#" class="icon-cross"></a><input type="hidden" name="fichier[]" value="'+data+'" /></span>');
			iniFiles();
        }
	});
	*/



	// TEST VALIDATION FORMULAIRE ?
	if($("#plupload").length > 0) {
		var uploader = new plupload.Uploader({
			// OJ
			button_browse_hover : true,
			autostart: true,
			// drop_element : "plupload",
			browse_button : 'plupload',
	        container: "_files",

			// General settings
			runtimes : 'html5,flash,html4',//,silverlight,browserplus,html5,gears',
			url : '/'+lang+'/plupload.php',
			max_file_size : '30mb',
			chunk_size : '1mb',
			// unique_names : true,

			// Specify what files to browse for
			filters : [
				{title : "Files", extensions : "doc,docx,txt"}
			],

			// Flash settings
			flash_swf_url : 'include/lib/plupload/js/plupload.flash.swf'

		});
		uploader.init();

		uploader.bind('FilesAdded', function(uploader, files, Response) {
			// alert("files added");
	        var renderString = [];
	        $.each(files, function(i, file) {
	            renderString.push('<div id="'+file.id+'" class="fileItem">');
	            renderString.push('	<span class="name">'+file.name+'</span>');
	            renderString.push('	<span class="size">'+plupload.formatSize(file.size)+'</span>');
	            renderString.push('	<div class="plupload_progress"><div class="plupload_progress_bar"></div></div>');
	            renderString.push(' <span class="spinner-container"></span>');
	            renderString.push('</div>');
	        });
	        if($('#plupload .upload-feedback').length > 0) {
	        	$('#plupload .upload-feedback').append(renderString.join(''));
	        } else {
	        	$('#plupload').append('<div class="upload-feedback">'+renderString.join('')+'</div>');
	        }
	        uploader.refresh();
	        uploader.start();
	    });

	    // uploader.bind('UploadProgress', function(up, file) {
	    //     $("#plupload").removeClass("hover");
     //        var $fileWrapper = $('#' + file.id);
     //        $fileWrapper.find(".plupload_progress").show();
     //        $fileWrapper.find(".plupload_progress_bar").attr("style", "width:"+ file.percent + "%");
     //        if(file.percent >= 99) {

     //        	$fileWrapper.find(".plupload_progress").html("Finalisation en cours");
     //        	// alert("100%");
     //        }
     //    });

        uploader.bind('FileUploaded', function(up, file, Response) {
            var $fileItem = $('#' + file.id);
            $("#_files").append(Response.response);
            $fileItem.fadeOut('slow');
            $('#plupload').removeClass('hover');
            iniFiles();
            // iniEditDel();
        });

        // uploader.bind('UploadComplete', function(up, file, Response) {
        // 	$('#plupload').find('.upload-feedback').remove();
        // });

	    // $('#plupload').bind('dragover drop', function(e) {
     //        $(this).addClass('hover');
     //        e.preventDefault();
     //    }).bind('dragexit', function(e) {
     //        $(this).removeClass('hover');
     //        e.preventDefault();
     //    });
	}
	
	iniFiles();

   
});